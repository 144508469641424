<template>
  <div>
    <div class="header-default">
      <div class="header-default-menu">
        <div class="control">
          <div @click="$emit('doback', 'back')" class="question—btn">
            <el-button size="mini">
              <i class="fas fa-arrow-circle-left"></i>
              <b> BACK</b>
            </el-button>
          </div>
          <div
            class="review"
            v-if="question_detail.type == 'multiple'"
            v-show="!showReview"
            size="mini"
          >
            <el-button @click="$emit('showViewText')" size="mini">
              <b class="review_text" v-show="!showViewText">
                VIEWTEXT
              </b>
              <b class="return_text" v-show="showViewText">
                RETURN
              </b>
            </el-button>
          </div>
          <div class="review">
            <el-button @click="$emit('showreview')" size="mini">
              <b class="review_text" v-show="!showReview">REVIEW</b>
              <b class="return_text" v-show="showReview">RETURN</b>
            </el-button>
          </div>
          <div @click="$emit('donext', 'next')" class="question—btn">
            <el-button size="mini">
              <b>NEXT </b>
              <i class="fas fa-arrow-circle-right"></i>
            </el-button>
          </div>
        </div>
      </div>
      <div class="header-default-btn">
        <div>
          Passage {{ question_detail.chapter_order }} -
          <b v-html="question_detail.chapter_title"></b>
        </div>
        <div class="time">
          <div @click="timeShow = !timeShow" v-show="!timeShow" size="mini">
            <b>TIME</b>
          </div>
          <div
            type="danger"
            @click="timeShow = !timeShow"
            size="mini"
            v-show="timeShow"
          >
            <span class="time_show">
              <b>{{ time.minute }}</b>
              <b>:</b>
              <b>{{ time.second }} </b>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["showViewText", "showReview", "time", "question_detail", "mode"],
  methods: {},
  data() {
    return {
      timeShow: true
    };
  }
};
</script>

<style src="@/views/toefl/toeflTest/mobile/style/tpo.css" scoped></style>
