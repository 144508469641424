<template>
  <div class="listening-passage col-xs-12 col-sm-12 col-md-12">
    <div class="intro_screen question cover" style="opacity: 1;">
      <p class="q_count text-center">
        Question<b> {{ question_detail.question_order }} </b>
      </p>
      <audio
        :src="question_detail.content.question_audio"
        @ended="stopPlay"
        @error="stopPlay"
        autoplay
      ></audio>
      <h3 class="q_title text-center" v-html="question_detail.question"></h3>
      <h6 class="tips">
        <b
          >Click on {{ this.question_detail.content.checkbox_count }} answers</b
        >
      </h6>
      <el-checkbox-group v-model="theAnswers" v-show="showOptions">
        <el-checkbox
          class="option"
          v-for="option in question_detail.options"
          :label="option.id"
          :key="option.id"
        >
          {{ option.title }}
        </el-checkbox>
      </el-checkbox-group>
      <GoogleAd v-if="false" :key="question_detail.id" />
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import GoogleAd from "@/views/ad/GoogleAd";
import _ from "lodash";
export default {
  props:["question_detail", "answers"],
  components: {
    GoogleAd
  },
  data() {
    return {
      theAnswers: _.cloneDeep(this.answers),
      showOptions: false
    };
  },
  mounted(){
    this.getAnswer();
  },
  watch: {
    "question_detail.id"(){
      this.showOptions = false;
    },
    answers(value){
      this.theAnswers = _.cloneDeep(value);
    },
    theAnswers(){
      if (this.theAnswers.length === Number(this.question_detail.content.checkbox_count)) {
        this.$emit("enableNextButton");
      } else {
        this.$emit("disableNextButton");
      }
      this.getAnswer();
    }
  },
  methods: {
    stopPlay() {
      this.showOptions = true;
      this.$emit("enableNextButton");
    },
    getAnswer(){
      if (this.theAnswers.length > Number(this.question_detail.content.checkbox_count)) {
        this.theAnswers.pop();
      }
      let answers = _.cloneDeep(this.theAnswers);
      answers.sort(this.cmp);
      this.$parent.setAnswers(answers);
    },
    cmp(a, b){
      return a - b;
    }
  }
};
</script>
<style src="@/views/toefl/toeflTest/mobile/style/tpo.css" scoped></style>
<style scoped>
.cover {
  height: 100%;
}
.option {
  display: block;
  margin: 40px 0;
  line-height: 22px;
  text-align: left;
}
.option >>> .el-checkbox__label {
  font-size: 16px;
}
.option >>> .el-checkbox__inner {
  width: 20px;
  height: 20px;
}
.option >>> .el-checkbox__inner::after {
  height: 8px;
  width: 4px;
  left: 6px;
  top: 3px;
}
.intro_screen {
  word-wrap: break-word;
  background-color: #fff;
  border: 1px solid #efefef;
  padding: 15px;
  overflow: auto;
  margin: 0;
}
</style>
