<template>
  <div style="height:100%">
    <div id="QuestionDefault" class="cover">
      <div class="box">
        <div class="text-center writing-title">
          Writing for an Academic Discussion
        </div>
        <div style="flex:1;width: 100%;overflow: auto;">
          <div>
            <div class="passage-left">
              <div class="passage-left-box">
                <div>
                  <p>
                    <b>Instructions: </b>
                    Your professor is teaching a class on business. Write a post
                    responding to the professor's question. In your response you
                    should:
                  </p>
                  <ul class="ml-4">
                    <li>express and support your personal opinion</li>
                    <li>make a contribution to the discussion</li>
                  </ul>
                  <p>An effective response will contain at least 100 words.</p>
                </div>
                <div class="speaker-avatar mt-3 mb-2">
                  <div>
                    <el-avatar
                      :size="180"
                      src="https://ivy-way-toefl.s3.ap-northeast-1.amazonaws.com/images/user/Dr.png"
                    ></el-avatar>
                  </div>
                  <h4>Dr.Diaz</h4>
                </div>
                <p v-html="question_detail.content.professor"></p>
              </div>
            </div>
          </div>
          <div class="speaker-box pl-3 pr-3">
            <div class="speaker">
              <div class="speaker-avatar">
                <div>
                  <el-avatar
                    :size="90"
                    src="https://ivy-way-toefl.s3.ap-northeast-1.amazonaws.com/images/user/Karen.png"
                  ></el-avatar>
                </div>
                <h6>Karen</h6>
              </div>
              <div class="speaker-info" v-html="question_detail.content.female"></div>
            </div>
            <div class="speaker">
              <div class="speaker-avatar">
                <div>
                  <el-avatar
                    :size="90"
                    src="https://ivy-way-toefl.s3.ap-northeast-1.amazonaws.com/images/user/Brad.png"
                  ></el-avatar>
                </div>
                <h6>Brad</h6>
              </div>
              <div class="speaker-info" v-html="question_detail.content.male"></div>
            </div>
            <hr />
            <div class="article-input">
              <div class="article-answer" v-if="mode == '1'">
                <div class="button-group mode-1 mb-2" style="width:100%;">
                  <div>
                    <el-button @click="copy">Copy</el-button>
                    <el-button @click="cut">Cut</el-button>
                    <el-button @click="paste">Paste</el-button>
                  </div>
                  <div>
                    <el-button type="text">Word Count</el-button>
                    <b style="margin: 0 20px"> {{ count }}</b>
                  </div>
                </div>
                <div style="flex-grow: 1">
                  <el-input
                    type="textarea"
                    style="width:100%;height:100%"
                    placeholder="Please type your response here"
                    v-model="theAnswer"
                  >
                  </el-input>
                </div>
              </div>
              <div class="article-answer" v-else>
                <div class="button-group" style="height: 70px">
                  <div>
                    <el-button @click="copy">Copy</el-button>
                    <el-button @click="cut">Cut</el-button>
                    <el-button @click="paste">Paste</el-button>
                  </div>
                  <div>
                    <el-button type="text">Word Count</el-button>
                    <b style="margin: 0 20px"> {{ count }}</b>
                  </div>
                </div>
                <div style="flex:1;">
                  <el-input
                    id="answer"
                    type="textarea"
                    style="height:100%;"
                    placeholder="Please type your response here"
                    v-model="theAnswer"
                  >
                  </el-input>
                </div>
              </div>
            </div>
          </div>
          <div v-if="false">
            <GoogleAd :key="question_detail.id" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import GoogleAd from "@/views/ad/GoogleAd";
export default {
  props:["question_detail", "answer", "prepare_time", "answer_time", "mode", "count"],
  components:{
    GoogleAd
  },
  data() {
    return {
      showBar: false,
      theAnswer:this.answer,
      totalText: "",
      clipbrd: "",
      passage: [ ` We are all exposed to a lot of advertising, especially on the
                  internet. And of course businesses spend a lot of money to
                  create and distribute advertising.Before next class, I would
                  like for you to discuss this question: Cut I think w Paste Undo
                  Redo Hide Word Count 2 ls advertising just a way of
                  manipulating people to buy things they do not need, or is it an
                  important source of information that helps people make informed
                  consumer decisions?`, ` People can find out a lot about products from
                    advertising.There's plenty of evidence that people usually
                    begin the process of making a big purchase by looking at ads
                    and reviews.I have to go to another class right now, but I'm
                    going to post later about an advertisement that gave me a
                    lot of useful information.`, `People can find out a lot about products from
                    advertising.There's plenty of evidence that people usually
                    begin the process of making a big purchase by looking at ads
                    and reviews.I have to go to another class right now, but I'm
                    going to post later about an advertisement that gave me a
                    lot of useful information.`]
    };
  },
  watch: {
    answer(){
      this.theAnswer = this.answer;
    },
    theAnswer() {
      this.getAnswer();
    }
  },
  methods: {
    getAnswer(){
      let answers = [];
      answers.push(this.theAnswer);
      this.$parent.setAnswers(answers);
    },
    getQuestion(val) {
      let text = val.replace(
        /Directions: You have 20 minutes to plan and write your response. Your response will be judged on the basis of the quality of your writing and on how well your response presents the points in the lecture and their relationship to the reading passage. Typically, an effective response will be 150 to 225 words./,
        ""
      );
      return text;
    },
    copy(){
      this.clipbrd = this.getSelect();
    },
    cut(){
      let clipbrd = "";
      let textBox = document.getElementById("answer");
      textBox.focus();
      if(textBox.selectionStart >= 0){
        clipbrd = textBox.value.substring(textBox.selectionStart,textBox.selectionEnd);
      }else if(document.selection){
        clipbrd = document.selection.createRange().text;
      }
      this.clipbrd = clipbrd;
      let str = textBox.value.substring(0, textBox.selectionStart) + textBox.value.substring(textBox.selectionEnd,textBox.value.length);
      this.theAnswer = str;
    },
    paste(){
      let obj = document.getElementById("answer");
      let str = this.clipbrd;
      obj.focus();
      if (document.selection) {
        var sel = document.selection.createRange();
        sel.text = str;
      } else if (typeof obj.selectionStart == "number"
          && typeof obj.selectionEnd == "number") {
        var startPos = obj.selectionStart, endPos = obj.selectionEnd, cursorPos = startPos, tmpStr = obj.value;
        obj.value = tmpStr.substring(0, startPos) + str
            + tmpStr.substring(endPos, tmpStr.length);
        cursorPos += str.length;
        obj.selectionStart = obj.selectionEnd = cursorPos;
      } else {
        obj.value += str;
      }
      this.theAnswer = obj.value;
    },
    getSelect(){
      let textBox = document.getElementById("answer");
      let str = "";
      if(textBox.selectionStart >= 0){
        str = textBox.value.substring(textBox.selectionStart,textBox.selectionEnd);
      }else if(document.selection){
        str =  document.selection.createRange().text;
      }
      return str;
    }
  }
};
</script>
<style src="@/views/toefl/toeflTest/style/tpo.css" scoped></style>
<style scoped>
/* .article-answer {
  height: 100%;
} */
#QuestionDefault .box {
  /* border: 1px solid #ccc; */
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  line-height: 1.3;
}
.writing-title {
  background-color: var(--themeColor);
  color: white;
  font-size: 1.4rem;
  font-weight: 700;
  height: 80px;
  line-height: 80px;
}
.speaker-box {
  /* height: 50%; */
}
.speaker {
  display: flex;
  margin-bottom: 3rem;
}
.speaker-avatar {
  display: flex;
  flex-direction: column;
  width: 500px;
  align-items: center;
  justify-content: center;
}
.passage-left-box .speaker-avatar {
  width: 100%;
}
.passage-left {
  padding: 1rem;
  height: 100%;
}
.passage-left-box {
  border: 1px solid #dcdfe6;
  border-radius: 1rem;
  height: 100%;
  padding: 1rem;
}
.cover {
  height: 100%;
  /* padding: 1rem; */
}
.intro_screen {
  word-wrap: break-word;
  background-color: #fff;
  border: 1px solid #dcdfe6;
  padding: 2%;
  overflow: auto;
  margin: 0;
}
.time-container {
  margin: 0 auto;
}
#QuestionDefault >>> .el-textarea textarea {
  height: 100%;
}
.article {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: 1rem;
  line-height: 1.3;
}
.speaker-info {
  padding-left: 1rem;
}
.article-text {
  padding-right: 20px;
  flex: 1;
}
.article-answer {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.article-input {
  /* height: 50%; */
  flex: 1;
}
.directions-box {
  border: 1px solid #cacbcc;
  background-color: #f2f3f5;
  padding: 20px;
}
.question-style {
  margin-top: 20px;
}
.button-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mode-1 {
  background: #f2f3f5;
  padding: 10px;
}
::v-deep .mode-1 button {
  background-color: #283773;
  min-width: 80px;
  color: #fff;
  height: 35px;
  line-height: 35px;
  font-size: 12px;
  font-weight: bold;
  padding: 0 12px;
  margin: 0 10px;
  border-radius: 3px;
  border: none;
  box-shadow: inset 0 3px 2px -1px #989fbc, inset -3px 0 2px -1px #636e99,
    inset 0 -3px 2px -1px #151d3b, inset 3px 0 2px -1px #1b254d;
}
::v-deep .el-textarea__inner {
  border-radius: 1rem;
  min-height: 200px!important;
}
</style>
