<template>
  <div>
   <div class="header-default">
      <div class="header-default-menu">
        <div class="control text-center">
          <div></div>
          <div class="question—btn">
            <el-button
              size="mini"
              @click="$emit('dook', 'next')"
              :disabled="disableNextButton"
            >
              <b>NEXT </b>
              <i class="fas fa-arrow-circle-right"></i>
            </el-button>
          </div>
        </div>
      </div>
      <div class="header-default-btn">
        <div class="passage-title">
          Passage {{ question_detail.chapter_order }} -
          <b v-html="question_detail.chapter_title"></b>
        </div>
        <div class="time">
          <div @click="timeShow = !timeShow" v-show="!timeShow" size="mini">
            <b>TIME</b>
          </div>
          <div
            type="danger"
            @click="timeShow = !timeShow"
            size="mini"
            v-show="timeShow"
          >
            <span class="time_show">
              <b>{{ time.minute }}</b>
              <b>:</b>
              <b>{{ time.second }} </b>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MainMenu",
  props: ["answers", "mode", "time", "question_detail", "disableNextButton"],
  watch: {
    answers() {
      this.showAnswer = false;
    }
  },
  methods: {},
  data() {
    return {
      showAnswer: false,
      timeShow: true
    };
  }
};
</script>
<style src="@/views/toefl/toeflTest/mobile/style/tpo.css" scoped></style>
<style scoped>
.your_ansers {
  line-height: 80px;
}

.passage-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
