<template>
  <div class="listening-passage">
    <div class="intro_screen cover">
      <div class="">
        <p class="q_count text-center">
          Question<b> {{ question_detail.question_order }} </b>
        </p>
        <div class="init_title text">
          <h3 class="q_title">
            {{ question_detail.question }}
          </h3>
        </div>
        <div class="">
          <div class="">
            <h6 class="text-center"><b>Options</b></h6>
            <draggable :list="options" group="people">
              <div
                class="list-item"
                v-for="element in options"
                :key="element.id"
              >
                {{ element.title }}
              </div>
            </draggable>
            <GoogleAd v-if="false" :key="question_detail.id" />
          </div>
          <div style="margin-top:20px">
            <h6 class="text-center"><b>Answer Choices</b></h6>
            <draggable
              class="list-group"
              :list="myAnswers"
              group="people"
              @change="getAnswer"
            >
              <div
                class="list-item"
                v-for="element in myAnswers"
                :key="element.id"
              >
                {{ element.title }}
              </div>
            </draggable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import GoogleAd from "@/views/ad/GoogleAd";
import _ from "lodash";

export default {
  components: {
    draggable,
    GoogleAd
  },
  props: ["question_detail", "answers"],
  data() {
    return {
      options: _.cloneDeep(this.question_detail.options),
      myAnswers: []
    };
  },
  mounted() {
    this.getAnswer();
    this.$emit("enableNextButton");
  },
  watch: {
    answers() {
      let options = [];
      let myAnswers = [];
      this.question_detail.options.forEach(option => {
        let index = this.answers.indexOf(option.id);
        if (index > -1) {
          myAnswers.push(option);
        } else {
          options.push(option);
        }
      });
      this.options = options;
      this.myAnswers = myAnswers;
      this.getAnswer();
    }
  },
  methods: {
    getAnswer() {
      let answers = [];
      this.myAnswers.forEach(option => {
        answers.push(option.id);
      });
      this.$parent.setAnswers(answers);
    }
  }
};
</script>
<style src="@/views/toefl/toeflTest/mobile/style/tpo.css" scoped></style>
<style scoped>
.list-group {
  min-height: 100px;
  margin: 20px 0;
  border: 1px solid #ccc;
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.list-item {
  border: 1px solid #cacbcc;
  background-color: #f2f3f5;
  padding: 20px 10px;
  margin: 10px 0;
}
</style>
