<template>
  <div class="reading">
    <test-introduction
      v-if="showIntroduction"
      :mode="mode"
      @showintroductionclick="testStart"
      :question_detail="question_detail"
    ></test-introduction>
    <div class="content cover" style="width:100%" v-else>
      <main-menu
        :showReview="showReview"
        :time="time"
        :question_detail="question_detail"
        :mode="mode"
        :showViewText="showViewText"
        @donext="getQuestion"
        @doback="getQuestion"
        @showreview="showReview = !showReview"
        @showViewText="showViewText = !showViewText"
      ></main-menu>

      <div v-show="!showReview">
        <!--非拖拽题目显示的页面-->
        <div
          class="single row mar"
          v-if="
            question_detail.type !== 'multiple' &&
              question_detail.type !== 'double'
          "
        >
          <div :style="changeTop">
            <question-default
              v-if="question_detail.type === 'default'"
              :question_detail="question_detail"
              @setAnswers="setAnswers"
              :answers="answers"
            ></question-default>
            <question-checkbox
              v-if="question_detail.type === 'checkbox'"
              :question_detail="question_detail"
              @setAnswers="setAnswers"
              :answers="answers"
            >
            </question-checkbox>
            <question-click
              v-if="question_detail.type === 'click'"
              :question_detail="question_detail"
              @setAnswers="setAnswers"
              @insertPassage="insertPassage"
              :insertOption="insertOption"
              :answers="answers"
            ></question-click>
          </div>
          <div class="change-box" :style="changeCenter">
            <template v-if="boxType === 0">
              <el-button
                type="primary"
                size="small"
                style="width:100%"
                @click="boxType = 1"
              >
                <i class="fas fa-arrow-down"></i>
              </el-button>
              <el-button
                type="primary"
                size="small"
                style="width:100%;margin:0"
                @click="boxType = 2"
              >
                <i class="fas fa-arrow-up"></i>
              </el-button>
            </template>
            <template v-if="boxType === 1">
              <el-button
                type="primary"
                size="small"
                style="width:100%;margin:0"
                @click="boxType = 3"
              >
                <i class="fas fa-arrow-down"></i>
              </el-button>
              <el-button
                type="primary"
                size="small"
                style="width:100%;margin:0"
                @click="boxType = 0"
              >
                <i class="fas fa-arrow-up"></i>
              </el-button>
            </template>
            <template v-if="boxType === 2">
              <el-button
                v-show="boxType === 2"
                type="primary"
                size="small"
                style="width:100%;margin:0"
                @click="boxType = 0"
              >
                <i class="fas fa-arrow-down"></i>
              </el-button>
              <el-button
                v-show="boxType === 2"
                type="primary"
                size="small"
                style="width:100%;margin:0"
                @click="boxType = 4"
              >
                <i class="fas fa-arrow-up"></i>
              </el-button>
            </template>
            <template v-if="boxType === 3">
              <el-button
                type="info"
                size="small"
                style="width:100%;margin:0"
                disabled
              >
                <i class="fas fa-arrow-down"></i>
              </el-button>
              <el-button
                type="primary"
                size="small"
                style="width:100%;margin:0"
                @click="boxType = 1"
              >
                <i class="fas fa-arrow-up"></i>
              </el-button>
            </template>
            <template v-if="boxType === 4">
              <el-button
                type="primary"
                size="small"
                style="width:100%;margin:0"
                @click="boxType = 2"
              >
                <i class="fas fa-arrow-down"></i>
              </el-button>
              <el-button
                type="info"
                size="small"
                style="width:100%;margin:0"
                disabled
              >
                <i class="fas fa-arrow-up"></i>
              </el-button>
            </template>
          </div>
          <div :style="changeBottom">
            <passage-default
              :question_detail="question_detail"
              :mode="mode"
              @insertAnswer="insertAnswer"
              :insertTitle="insertTitle"
              :showPassage="false"
            ></passage-default>
          </div>
        </div>
        <!--拖拽排序题目显示的页面-->
        <div class="multiple" v-else>
          <question-multiple
            v-if="question_detail.type === 'multiple' && !showViewText"
            :question_detail="question_detail"
            :answers="answers"
          ></question-multiple>
          <question-double
            v-if="question_detail.type === 'double' && !showViewText"
            :question_detail="question_detail"
            :answers="answers"
          ></question-double>
          <passage-default
            v-if="showViewText"
            :question_detail="question_detail"
            :mode="mode"
            @insertAnswer="insertAnswer"
            :insertTitle="insertTitle"
            :showPassage="true"
          ></passage-default>
        </div>
      </div>
      <review-question
        v-if="showReview"
        :texts="filterReading(questions)"
        :allAnswers="allAnswers"
      ></review-question>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import _ from "lodash";
import TestIntroduction from "./components/TestIntroduction";
import MainMenu from "./components/MainMenu";
import QuestionDefault from "./components/QuestionDefault";
import QuestionClick from "./components/QuestionClick";
import PassageDefault from "./components/PassageDefault";
import QuestionMultiple from "./components/QuestionMultiple";
import QuestionDouble from "./components/QuestionDouble";
import ReviewQuestion from "./components/ReviewQuestion";
import QuestionCheckbox from "./components/QuestionCheckbox";

export default {
  metaInfo() {
    return {
      title: "TOEFL - Reading"
    };
  },

  components: {
    TestIntroduction,
    MainMenu,
    QuestionDefault,
    QuestionClick,
    QuestionMultiple,
    QuestionDouble,
    PassageDefault,
    ReviewQuestion,
    QuestionCheckbox
  },

  mixins: [],

  props: ["questions", "question_detail", "allAnswers", "answers", "mode"],
  data() {
    return {
      showReview: false,
      showViewText: false,
      showIntroduction: true,
      user_exam: {},
      question_count: 0,
      time: {
        minute: "54",
        second: "00"
      },
      insertOption: "",
      insertTitle: "",
      boxType: 0
    };
  },
  computed: {
    progress_remaining() {
      return parseInt(this.time.minute) * 60 + parseInt(this.time.second);
    },
    changeTop() {
      let str = "";
      if (this.boxType === 0) {
        str = "height: 47%";
      }
      if (this.boxType === 1) {
        str = "height: 69%";
      }
      if (this.boxType === 2) {
        str = "height: 25%";
      }
      if (this.boxType === 3) {
        str = "height: 94%";
      }
      if (this.boxType === 4) {
        str = "display:none";
      }
      return str;
    },
    changeBottom() {
      let str = "";
      if (this.boxType === 0) {
        str = "height: 47%";
      }
      if (this.boxType === 1) {
        str = "height: 25%";
      }
      if (this.boxType === 2) {
        str = "height: 69%";
      }
      if (this.boxType === 3) {
        str = "display:none";
      }
      if (this.boxType === 4) {
        str = "height: 94%";
      }
      return str;
    },
    changeCenter() {
      let str = "height: 6%";
      return str;
    }
  },
  watch: {},
  mounted() {},
  directives: {},
  methods: {
    getResolve(question_id) {
      const { href } = this.$router.resolve({
        name: "ToeflResolve",
        query: {
          user_exam_id: this.user_exam_id,
          question_id: question_id
        }
      });
      return href;
    },
    filterReading(questions) {
      return _.filter(questions, ["skill_name", "reading"]);
    },
    //获取数据
    getQuestion(value) {
      //判断前进还是后退；
      this.$parent.getQuestion(value, this.progress_remaining);
    },
    testStart() {
      // let question = this.questions[0]["id"];
      this.showIntroduction = false;
      if (this.questions.length >= 20) {
        this.countDown(3240);
      } else {
        this.time.minute = "36";
        this.countDown(2160);
      }
    },
    countDown(seconds_remaining) {
      setInterval(() => {
        var minute;
        var second;
        minute = Math.floor(seconds_remaining / 60);
        second = Math.floor(seconds_remaining) - minute * 60;
        // 让个位数的时间正常显示
        if (minute <= 9) minute = "0" + minute;
        if (second <= 9) second = "0" + second;

        this.time.minute = minute;
        this.time.second = second;

        seconds_remaining--;
        if (seconds_remaining == 0) {
          // location.href = "listening.php?test_id=" + test_id + "&id=" + id;
        }
      }, 1000);
    },
    setAnswers(answers) {
      this.$emit("setAnswers", answers);
    },
    selectQuestion(question_index) {
      this.showReview = false;
      this.$parent.skipQuestion(question_index);
    },
    insertAnswer(data) {
      this.insertOption = data;
    },
    insertPassage(data) {
      this.insertTitle = data;
    }
  }
};
</script>
<style src="@/views/toefl/toeflTest/mobile/style/tpo.css" scoped></style>
<style scoped>
.dragble-box {
  width: 100%;
  height: 100px;
  background: #ccc;
}
.change-box {
  padding: 0 15px;
  width: 100%;
  height: 12%;
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
}
</style>
