<template>
  <div class="text-center">
    <!-- <el-card class="box-card"> -->
    <span class="result-icon text-success">
      <i class="fas fa-check-circle"></i>
    </span>
    <p class="result-title">
      {{ $t("toefl.testCompleted") }}
    </p>
    <div class="">
      <p class="result-title" v-html="$t('toefl.shareText')" />
      <div class="text-center">
        <iframe
          src="https://www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2Ftestadmit&width=450&layout=standard&action=like&size=large&share=true&height=35&appId"
          width="370"
          height="35"
          style="border:none;overflow:hidden;margin:0 auto"
          scrolling="no"
          frameborder="0"
          allowfullscreen="true"
          allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
        ></iframe>
      </div>
    </div>
    <hr style="margin: 15px 0" />
    <el-button
      type="success"
      size="small"
      @click="$emit('toTranscript')"
      style="width:100%"
    >
      {{ $t("toefl.viewTestResult") }}
    </el-button>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
export default {
  metaInfo() {
    return {
      title: this.CompanyName
    };
  },

  components: {},

  mixins: [],

  props: ["mode"],
  data() {
    return {
      showQuestion: false,
      page: 1,
      count: 0
    };
  },
  computed: {},
  watch: {},

  created() {},
  methods: {}
};
</script>
<style src="@/views/toefl/toeflTest/mobile/style/tpo.css" scoped></style>
<style scoped>
.fb-like.fb_iframe_widget {
  bottom: 5px;
}
.result-icon {
  font-size: 50px;
  margin-bottom: 20px;
}
.result-title {
  font-size: 14px;
  color: #5e6d82;
  line-height: 35px;
}
</style>
