<template>
  <div>
    <div>
      <direction
        v-if="page === 0"
        :question_detail="question_detail"
        :mode="mode"
      ></direction>
      <div v-if="question_detail.type === 'default'">
        <div v-if="page == 1">
          <question-default
            :mode="mode"
            :question_detail="question_detail"
            prepare_time="15"
            answer_time="45"
            :showOk="showOk"
            :audio_src="audio_src"
            :key="question_detail.chapter_order"
          ></question-default>
        </div>
      </div>
      <div v-if="question_detail.type === 'article_audio'">
        <article-passage
          v-if="page == 1"
          :mode="mode"
          :key="question_detail.chapter_order"
          :question_detail="question_detail"
        ></article-passage>
        <audio-passage
          v-if="page == 2"
          :mode="mode"
          :key="question_detail.chapter_order"
          :question_detail="question_detail"
        ></audio-passage>
        <div v-if="page == 3">
          <question-default
            :mode="mode"
            :question_detail="question_detail"
            prepare_time="30"
            answer_time="60"
            :showOk="showOk"
            :audio_src="audio_src"
            :key="question_detail.chapter_order"
          ></question-default>
        </div>
      </div>
      <div v-if="question_detail.type === 'audio'">
        <audio-passage
          v-if="page == 1"
          :mode="mode"
          :key="question_detail.chapter_order"
          :question_detail="question_detail"
        ></audio-passage>
        <div v-if="page == 2">
          <question-default
            :mode="mode"
            :question_detail="question_detail"
            prepare_time="20"
            answer_time="60"
            :showOk="showOk"
            :audio_src="audio_src"
            :key="question_detail.chapter_order"
          ></question-default>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import HZRecorder from "js-audio-recorder";
import Direction from "./components/Direction";
import AudioPassage from "./components/AudioPassage";
import ArticlePassage from "./components/ArticlePassage";
import QuestionDefault from "./components/QuestionDefault";
import TOEFL from "@/apis/toefl";

export default {
  metaInfo() {
    return {
      title: "Speaking - " + this.CompanyName
    };
  },

  components: {
    Direction,
    QuestionDefault,
    ArticlePassage,
    AudioPassage
  },

  mixins: [],

  props: ["question_detail", "mode"],
  data() {
    return {
      page: 0,
      isComplete: false,
      isVoice: false,
      audio_src: {},
      recorder: {},
      stream: null,
      showOk: false,
      myBlob: null
    };
  },
  computed: {},
  watch: {
    "question_detail.id"() {
      this.isVoice = false;
      this.showOk = false;
      if (this.question_detail.content.direction_audio) {
        this.page = 0;
      } else {
        this.page = 1;
      }
    }
  },

  mounted() {
    if (
      navigator.mediaDevices.getUserMedia ||
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia
    ) {
      this.getUserMedia({ audio: true }); // 调用用户媒体设备，访问摄像头、录音
    }
    if (this.question_detail.content.direction_audio) {
      this.page = 0;
    } else {
      this.page = 1;
    }
  },
  destroyed() {
    this.stream.getTracks().forEach(track => {
      track.stop();
    });
  },
  methods: {
    getUserMedia(constrains) {
      let that = this;
      if (navigator.mediaDevices.getUserMedia) {
        // 最新标准API
        navigator.mediaDevices
          .getUserMedia(constrains)
          .then(stream => {
            that.success(stream);
          })
          .catch(err => {
            that.error(err);
          });
      } else if (navigator.webkitGetUserMedia) {
        // webkit内核浏览器
        navigator
          .webkitGetUserMedia(constrains)
          .then(stream => {
            that.success(stream);
          })
          .catch(err => {
            that.error(err);
          });
      } else if (navigator.mozGetUserMedia) {
        // Firefox浏览器
        navigator
          .mozGetUserMedia(constrains)
          .then(stream => {
            that.success(stream);
          })
          .catch(err => {
            that.error(err);
          });
      } else if (navigator.getUserMedia) {
        // 旧版API
        navigator
          .getUserMedia(constrains)
          .then(stream => {
            that.success(stream);
          })
          .catch(err => {
            that.error(err);
          });
      }
    },
    // 成功的回调函数
    success(stream) {
      stream = {
        sampleBits: 16,
        sampleRate: 48000,
        numChannels: 1
      };
      this.stream = stream;
      this.recorder = new HZRecorder(stream);
    },
    // 异常的回调函数
    error(error) {
      console.log("Access to user media device failed:", error.name, error.message);
    },
    hiddenPassage() {
      this.showQuestion = true;
    },
    getQuestion(value) {
      this.$parent.getQuestion(value, 0);
    },
    setAnswers(answers) {
      this.$emit("setAnswers", answers);
    },
    //录音开始和结束
    readyOriginal() {
      // let recorder = this.recorder;
      if (!this.isVoice) {
        this.recorder = new HZRecorder(this.stream);
        this.recorder && this.recorder.start();
        this.isVoice = true;
        this.isComplete = false;
      } else {
        this.isVoice = false;
        this.recorder && this.recorder.stop();
        this.isComplete = true;
        this.audio_src = window.URL.createObjectURL(this.recorder.getWAVBlob());
        let myBlob = this.recorder.getWAVBlob();
        this.myBlob = myBlob;
      }
    },

    //播放录音
    playAudio() {
      let audio = document.getElementById("audio");
      audio.play();
    },
    setPage() {
      this.page++;
    },
    async uploadAudio() {
      let document = this.myBlob;
      const formData = new FormData();
      formData.append("type", "toefl_listening");
      formData.append("document", document, "record.wav");
      const res = await TOEFL.uploadAudio(formData);
      let answers = [];
      answers.push(res.document.url);
      this.$emit("setAnswers", answers);
      this.showOk = true;
    }
  }
};
</script>

<style src="@/views/toefl/toeflTest/mobile/style/tpo.css" scoped></style>
