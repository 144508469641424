<template>
  <div>
    <passage-default
      :question_detail="question_detail"
      :mode="mode"
      v-if="showQuestion == false"
    >
    </passage-default>
    <div v-else>
      <div>
        <div
          v-if="
            question_detail.type === 'default' &&
              (!question_detail.content.repeat_audio ||
                question_detail.content.repeat_audio == '')
          "
        >
          <main-menu
            :mode="mode"
            @dook="getQuestion"
            :time="time"
            :question_detail="question_detail"
            :disableNextButton="disableNextButton"
          ></main-menu>
          <question-default
            :question_detail="question_detail"
            @setAnswers="setAnswers"
            @enableNextButton="enableNextButton"
            :answers="answers"
          ></question-default>
        </div>
        <div v-if="['checkbox', 'listening_match'].includes(question_detail.type)">
          <main-menu
            :mode="mode"
            @dook="getQuestion"
            :time="time"
            :question_detail="question_detail"
            :disableNextButton="disableNextButton"
          ></main-menu>
          <question-checkbox
            :question_detail="question_detail"
            @setAnswers="setAnswers"
            @enableNextButton="enableNextButton"
            @disableNextButton="disableNextButton = true"
            :answers="answers"
          ></question-checkbox>
        </div>
        <div v-if="question_detail.type === 'sort'">
          <main-menu
            :mode="mode"
            @dook="getQuestion"
            :time="time"
            :question_detail="question_detail"
            :disableNextButton="disableNextButton"
          ></main-menu>
          <question-sort
            :question_detail="question_detail"
            @setAnswers="setAnswers"
            @enableNextButton="enableNextButton"
            :answers="answers"
          ></question-sort>
        </div>
        <div v-if="question_detail.type === 'multiple_choice'">
          <main-menu
            :mode="mode"
            @dook="getQuestion"
            :time="time"
            :question_detail="question_detail"
            :disableNextButton="disableNextButton"
          ></main-menu>
          <question-multiple-choice
            :question_detail="question_detail"
            @setAnswers="setAnswers"
            @enableNextButton="enableNextButton"
            :answers="answers"
          ></question-multiple-choice>
        </div>
        <div
          v-if="
            question_detail.type === 'default' &&
              question_detail.content.repeat_audio &&
              question_detail.content.repeat_audio !== null
          "
        >
          <question-repeat
            v-if="page === 1"
            :mode="mode"
            :question_detail="question_detail"
          >
          </question-repeat>
          <div v-if="page === 2">
            <main-menu
              :mode="mode"
              @dook="getQuestion"
              :time="time"
              :question_detail="question_detail"
              :disableNextButton="disableNextButton"
            ></main-menu>
            <question-default
              :question_detail="question_detail"
              @setAnswers="setAnswers"
              @enableNextButton="enableNextButton"
              :answers="answers"
            ></question-default>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import MainMenu from "./components/MainMenu";
import PassageDefault from "./components/PassageDefault";
import QuestionDefault from "./components/QuestionDefault";
import QuestionCheckbox from "./components/QuestionCheckbox";
import QuestionSort from "./components/QuestionSort";
import QuestionRepeat from "./components/QuestionRepeat";
import QuestionMultipleChoice from "./components/QuestionMultipleChoice";
// import TOEFL from "@/apis/toefl";

export default {
  metaInfo() {
    return {
      title: "Listening - " + this.CompanyName
    };
  },

  components: {
    MainMenu,
    PassageDefault,
    QuestionDefault,
    QuestionCheckbox,
    QuestionRepeat,
    QuestionSort,
    QuestionMultipleChoice
  },

  mixins: [],

  props: ["question_detail", "answers", "mode"],
  data() {
    return {
      disableNextButton: true,
      showQuestion: false,
      page: 1,
      time: {
        minute: "40",
        second: "00"
      }
    };
  },
  computed: {
    progress_remaining() {
      return parseInt(this.time.minute) * 60 + parseInt(this.time.second);
    }
  },
  watch: {
    question_detail() {
      this.page = 1;
      if (this.question_detail.question_order == 1) {
        this.showQuestion = false;
      } else {
        this.showQuestion = true;
      }
    }
  },

  mounted() {
    this.countDown(this.progress_remaining);
  },

  methods: {
    enableNextButton() {
      this.disableNextButton = false;
    },
    hiddenPassage() {
      this.showQuestion = true;
    },
    countDown(seconds_remaining, test_id) {
      this.timer = setInterval(() => {
        var minute;
        var second;
        minute = Math.floor(seconds_remaining / 60);
        second = Math.floor(seconds_remaining) - minute * 60;
        // 让个位数的时间正常显示
        if (minute <= 9) minute = "0" + minute;
        if (second <= 9) second = "0" + second;

        this.time.minute = minute;
        this.time.second = second;

        seconds_remaining--;
        if (seconds_remaining == 0) {
          // location.href = "listening.php?test_id=" + test_id + "&id=" + id;
        }
      }, 1000);
    },
    getQuestion(value) {
      //判断前进还是后退；
      this.disableNextButton = true;
      this.$parent.getQuestion(value, this.progress_remaining);
    },
    setAnswers(answers) {
      this.$emit("setAnswers", answers);
    },
    setPage() {
      this.page++;
    }
  }
};
</script>

<style src="@/views/toefl/toeflTest/mobile/style/tpo.css" scoped></style>
