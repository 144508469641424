<template>
  <div class="toefl">
    <div class="change-phone">
      <h6>檢測到您正在使用大型設備，請切換到桌面版本已獲得更好的體驗</h6>
      <el-button @click="changeDesk" style="width:100%">
        切換
      </el-button>
    </div>
    <div class="change-phone-cover"></div>
    <reading
      v-if="this.question_type === 'reading'"
      :questions="questions"
      :question_detail="questions[question_index]"
      :allAnswers="user_exam.exam_answers"
      :answers="answers"
      :mode="mode"
      @setAnswers="setAnswers"
    ></reading>
    <listening
      v-if="this.question_type === 'listening'"
      :question_detail="questions[question_index]"
      :answers="answers"
      :mode="mode"
      @setAnswers="setAnswers"
    ></listening>
    <speaking
      v-if="this.question_type === 'speaking'"
      :question_detail="questions[question_index]"
      :questions="questions"
      :mode="mode"
      @setAnswers="setAnswers"
    ></speaking>
    <writing
      v-if="this.question_type == 'writing'"
      :question_detail="questions[question_index]"
      :questions="questions"
      :mode="mode"
      @setAnswers="setAnswers"
    ></writing>
    <el-dialog
      :title="this.$t('message.notice')"
      :visible.sync="showLeaveAlert"
      width="95%"
      :close-on-click-modal="false"
      center
    >
      <div>
        <h6
          v-html="
            $t('toefl.leaveAlert', {
              counts: this.leaveCountText,
              times: this.leave_time,
              second: this.second
            })
          "
        ></h6>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="default" size="small" @click="showLeaveAlert = false">
          {{ this.$t("message.continue") }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="The exam has been completed"
      :visible.sync="showLike"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="95%"
    >
      <Finished :mode="mode" @toTranscript="toTranscript"></Finished>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import _ from "lodash";
import Reading from "@/views/toefl/toeflTest/mobile/reading/Index";
import Listening from "@/views/toefl/toeflTest/mobile/listening/Index";
import Speaking from "@/views/toefl/toeflTest/mobile/speaking/Index";
import Writing from "@/views/toefl/toeflTest/mobile/writing/Index";
import Finished from "@/views/toefl/toeflTest/mobile/Finished";
import TOEFL from "@/apis/toefl";

export default {
  metaInfo() {
    return {
      title: "TOEFL - " + this.CompanyName
    };
  },

  components: {
    Reading,
    Listening,
    Speaking,
    Writing,
    Finished
  },

  mixins: [],

  props: [],
  data() {
    return {
      showLeaveAlert: false,
      user_exam_id: 0,
      questions: [],
      question_index: 0,
      question_type: "",
      user_exam: {},
      answers: [],
      yourAnswers: [],
      isOld: false,
      question: 0,
      reading_status: null,
      listening_status: null,
      speaking_status: null,
      writing_status: null,
      showLike: false,
      spent: 0,
      leave_num: 0,
      leave_time: 0,
      title: "TOEFL - " + this.CompanyName,
      timer: null
    };
  },
  computed: {
    ...mapState("user", ["lang"]),
    _() {
      return _;
    },
    examId() {
      return this.$route.query.id;
    },
    userExamId() {
      return this.$route.query.user_exam_id;
    },
    mode() {
      return this.$route.query.mode;
    },
    is_finished() {
      if (this.question_index == this.questions.length - 1) {
        return true;
      } else {
        return false;
      }
    },
    leaveCountText() {
      let times = "time";
      if (this.lang.value === "EN-US") {
        if (this.leave_num <= 1) {
          times = this.leave_num + " " + "time";
        } else {
          times = this.leave_num + " " + "times";
        }
      } else {
        times = this.leave_num + " " + "次";
      }
      return times;
    },
    second() {
      let second = "second";
      if (this.lang.value === "EN-US") {
        if (this.leave_time <= 1) {
          second = "second";
        } else {
          second = "seconds";
        }
      } else {
        second = "秒";
      }
      return second;
    }
  },
  watch: {
    isOld(value) {
      if (value && !this.userExamId) {
        this.open();
      }
    }
  },
  async mounted() {
    setInterval(() => {
      this.spent++;
    }, 1000);
    let vm = this;
    let leaveStart = 0;
    window.onblur = function() {
      vm.showLeaveAlert = false;
      if (vm.$route.name == "MToeflTest") {
        leaveStart = vm.spent;
        vm.leave_num++;
        document.title =
          "Warning: Do not leave the browser in the middle of the test";
      }
    };
    window.onfocus = function() {
      vm.leave_time += vm.spent - leaveStart;
      if (vm.$route.name == "MToeflTest") {
        document.title = "TOEFL - " + this.CompanyName;
        if (vm.leave_time > 0) {
          vm.showLeaveAlert = true;
        }
      }
    };

    const { has_unfinished_exam } = await TOEFL.getUnfinishedExam(this.examId);
    if (has_unfinished_exam && !this.userExamId) {
      this.open();
    } else {
      this.getNewTest();
    }
  },

  methods: {
    changeDesk() {
      this.$router.replace({
        name: "ToeflTest",
        query: this.$route.query
      });
    },
    alertTest() {
      this.$alert(
        this.$t("toefl.leaveAlert", {
          counts: this.leaveCountText,
          times: this.leave_time,
          second: this.second
        }),
        "Warning",
        {
          confirmButtonText: "OK",
          type: "warning",
          callback: action => {
            // this.$message({
            //   type: "warning"
            // });
          }
        }
      );
    },
    async setQuestion(is_create) {
      const res = await TOEFL.getQuestions(this.examId, {
        is_create,
        other_status: [
          {
            mode: this.mode
          }
        ]
      });
      this.questions = res.questions;
      this.user_exam_id = res.user_exam_id;
    },
    async getNewTest() {
      await this.setQuestion(1);
      this.question = this.questions.exam_question_id;
      this.getQuestionInfo();
    },
    async setContinueTest() {
      await this.setQuestion(0);
      const answer_new = await TOEFL.getAnswers(this.user_exam_id);
      this.question = answer_new.user_exam.progress_question_id;
      this.leave_time = answer_new.user_exam.leave_time;
      this.leave_num = answer_new.user_exam.leave_num;
      this.user_exam = { ...answer_new.user_exam };
      this.getQuestionInfo();
    },
    //是否继续？
    open() {
      this.$confirm(
        this.$t("toefl.Unfinished Test Text"),
        this.$t("toefl.Unfinished Test"),
        {
          showClose: false,
          confirmButtonText: this.$t("toefl.Resume"),
          cancelButtonText: this.$t("toefl.Start Over"),
          center: true
        }
      )
        .then(() => {
          this.setContinueTest();
        })
        .catch(() => {
          this.getNewTest();
        });
    },
    getQuestionInfo() {
      this.setQuestionIndex();
      this.question_type = this.questions[this.question_index].skill_name;
    },
    setQuestionIndex() {
      const questionIndex = (this.questions || []).findIndex(
        ({ exam_question_id }) => exam_question_id === Number(this.question)
      );
      this.question_index = questionIndex === -1 ? 0 : questionIndex;
    },
    async getQuestion(value, progress_remaining) {
      //提交答案
      let user_exam_id = this.user_exam_id;
      let answers = this.yourAnswers;
      let exam_question_id = this.questions[this.question_index]
        .exam_question_id;
      let is_finished = this.is_finished;
      //记录每个单元是否完成的状态
      //判断前进还是后退
      const nextQuestionIndex =
        value === "back" ? this.question_index - 1 : this.question_index + 1;
      this.getAnswers(answers, nextQuestionIndex);
      if (value == "back") {
        this.question_index--;
        if (this.question_index < 0) {
          this.question_index = 0;
        }
      } else {
        if (!this.is_finished) {
          this.question_index++;
        } else {
          this.showLike = true;
          // return;
          // this.toTranscript();
        }
      }
      this.question_type = this.questions[this.question_index].skill_name;
      let progress_question_id = this.questions[this.question_index]
        .exam_question_id;
      //获取已经作答的答案
      if (answers.length > 0) {
        if (this.question_index < this.questions.length - 1) {
          if (
            this.questions[this.question_index + 1].skill_name !==
            this.questions[this.question_index].skill_name
          ) {
            if (
              this.questions[this.question_index].skill_name === "speaking" ||
              this.questions[this.question_index].skill_name === "writing"
            ) {
              this[this.questions[this.question_index].skill_name + "_status"] =
                "SCORING";
            } else {
              this[this.questions[this.question_index].skill_name + "_status"] =
                "DONE";
            }
          } else {
            this[this.questions[this.question_index].skill_name + "_status"] =
              "PROCESSING";
          }
        } else if (this.question_index === this.questions.length - 1) {
          if (
            this.questions[this.question_index].skill_name === "reading" ||
            this.questions[this.question_index].skill_name === "listening"
          ) {
            this[this.questions[this.question_index].skill_name + "_status"] =
              "DONE";
          } else {
            this[this.questions[this.question_index].skill_name + "_status"] =
              "SCORING";
          }
        }
        await TOEFL.updateAnswers(user_exam_id, {
          exam_question_id: exam_question_id,
          progress_remaining: this.spent,
          answers: answers,
          is_finished: is_finished,
          reading_status: this.reading_status,
          listening_status: this.listening_status,
          speaking_status: this.speaking_status,
          writing_status: this.writing_status,
          progress_question_id: progress_question_id,
          leave_time: this.leave_time,
          leave_num: this.leave_num
        });
      }
    },
    async skipQuestion(index) {
      this.question_index = index;
      this.question_type = this.questions[this.question_index].skill_name;
      //获取已经作答的答案
      this.getAnswers();
    },
    async getAnswers(answers, nextQuestionIndex) {
      if (this._.isEmpty(this.user_exam)) {
        const user_exam = await TOEFL.getAnswers(this.user_exam_id);
        this.user_exam = user_exam.user_exam;
      } else {
        if (answers) {
          const newAnswer = {
            answers,
            exam_question_id: this.questions[this.question_index]
              .exam_question_id,
            user_exam_id: this.user_exam_id
          };
          const isAlreadyAnsweredQuestionIndex = this.user_exam.exam_answers.findIndex(
            examAnswers =>
              examAnswers.exam_question_id ===
              this.questions[this.question_index].exam_question_id
          );
          if (isAlreadyAnsweredQuestionIndex > -1) {
            this.user_exam.exam_answers[
              isAlreadyAnsweredQuestionIndex
            ] = newAnswer;
            this.user_exam = {
              ...this.user_exam,
              exam_answers: [...this.user_exam.exam_answers]
            };
          } else {
            this.user_exam = {
              ...this.user_exam,
              exam_answers: [...this.user_exam.exam_answers, newAnswer]
            };
          }
        }
      }
      let targetExamQuestionId = null;
      if (nextQuestionIndex !== undefined) {
        targetExamQuestionId = this.questions[nextQuestionIndex]
          .exam_question_id;
      } else {
        targetExamQuestionId = this.user_exam.progress_question_id;
      }
      this.answers =
        this.user_exam.exam_answers.find(
          answer => answer.exam_question_id === targetExamQuestionId
        )?.answers || [];
    },
    setAnswers(answers) {
      if (answers[0] == null) {
        answers = [];
      }
      this.yourAnswers = answers;
    },
    selectQuestion(question_index) {
      this.question_index = question_index;
    },
    toTranscript() {
      this.routerPush({
        name: "toeflResult",
        query: {
          id: this.user_exam.id
        }
      });
    }
  }
};
</script>
<style src="@/views/toefl/toeflTest/mobile/style/tpo.css" scoped></style>
<style scoped>
::v-deep .el-dialog__body {
  padding: 15px;
}
</style>
<style>
@media screen and (max-width: 768px) {
  .el-message-box {
    width: 90%;
  }
}

.change-phone {
  width: 100%;
  background: var(--themeColor);
  position: fixed;
  z-index: 99999;
  bottom: 0;
  padding: 15px;
  color: white;
}
.change-phone h6 {
  margin-bottom: 15px;
  color: white;
}
.change-phone-cover {
  position: fixed;
  z-index: 100;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background: black;
  opacity: 0.8;
}
@media screen and (min-width: 768px) {
  .change-phone,
  .change-phone-cover {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .change-phone,
  .change-phone-cover {
    display: none;
  }
}
</style>
