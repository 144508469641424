<template>
  <div style="">
    <div class="header-default">
      <div class="header-default-menu">
        <div class="control">
          <div></div>
          <div>
            <div @click="setPage" class="question—btn">
              <el-button size="mini">
                <b>Start </b>
                <i class="fas fa-arrow-circle-right"></i>
              </el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="header-default-btn">
        <b>Speaking (Task {{ question_detail.chapter_order }}) </b>
      </div>
    </div>

    <div class="listening-passage row mar">
      <div class="intro_screen cover text-center">
        <img class="style_change" :src="imgUrl" />
        <audio
          id="listening_passage"
          ref="audioPlayer"
          :src="
            question_detail.content.direction_audio
              ? question_detail.content.direction_audio
              : 'https://ivy-way.s3.ap-northeast-1.amazonaws.com/toefl/audio/10seconds.wav'
          "
        ></audio>
        <h4>
          Please listen carefully.
        </h4>
        <div class="time-container col-md-6 col-md-offset-3">
          <div id="bar" style="width:0%;"></div>
        </div>
        <p class="col-md-12" id="total" style="color: #ccc"></p>
        <ReplayText @play="play" />
      </div>
    </div>
  </div>
</template>

<script>
import ReplayText from "@/views/toefl/toeflTest/ReplayText";

export default {
  components: {
    ReplayText
  },

  mixins: [],

  props: ["question_detail", "mode"],
  data() {
    return {
      imgUrl:
        "https://ivy-way.s3-ap-northeast-1.amazonaws.com/toefl/img/headset.jpeg",
      speed: 1,
      timer: null
    };
  },
  computed: {},
  watch: {},

  mounted() {
    this.play();
  },

  methods: {
    setPage() {
      this.$parent.setPage();
    },
    play() {
      let vm = this;
      this.$nextTick(() => {
        let passage_audio = document.getElementById("listening_passage");
        passage_audio.load();
        passage_audio.oncanplay = function() {
          passage_audio.play();
          vm.run(Math.round(passage_audio.duration));
        };
      });
    },
    countDown(seconds) {
      let minute;
      let second;
      minute = Math.floor(seconds / 60);
      second = Math.floor(seconds) - minute * 60;
      // 让个位数的时间正常显示
      if (minute <= 9) minute = "0" + minute;
      if (second <= 9) second = "0" + second;
      return minute + ":" + second;
    },
    run(audio_time) {
      let vm = this;
      let timer;
      let bar = document.getElementById("bar");
      let total = document.getElementById("total");
      let width = 0;
      let time = vm.countDown(audio_time);
      let second = 0;
      let surplus_time;
      if (this.timer) {
        clearInterval(vm.timer);
      }
      timer = window.setInterval(function() {
        second = second + 1 * vm.speed;
        if (second >= audio_time) {
          second = audio_time;
        }
        surplus_time = vm.countDown(second);
        width += (100 * vm.speed) / audio_time;
        bar.style.width = width + "%";
        total.innerHTML = surplus_time + "/" + time;
        if (second >= audio_time) {
          bar.style.width = "100%";
          clearInterval(timer);
          return true;
        }
      }, 1000);
      vm.timer = timer;
    }
  }
};
</script>
<style src="@/views/toefl/toeflTest/mobile/style/tpo.css" scoped></style>
<style scoped></style>
