<template>
  <div style="width: 100%;height: 100%">
    <audio
      id="beep_prepare"
      src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/toefl/audio/speaking_beep_prepare.mp3"
    ></audio>
    <audio
      id="beep_answer"
      src="https://ivy-way.s3-ap-northeast-1.amazonaws.com/toefl/audio/speaking_beep_answer.mp3"
    ></audio>
    <audio
      id="speaking_title"
      :src="question_detail.content.title_audio"
      @canplay="play()"
    ></audio>
    <div class="header-default">
      <div class="header-default-menu">
        <div class="control">
          <div></div>
          <div>
            <div v-show="showOk" @click="postAnswer" class="question—btn">
              <el-button size="mini">
                <b>Continue </b>
                <i class="fas fa-arrow-circle-right"></i>
              </el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="header-default-btn">
        <b>Speaking (Task {{ question_detail.chapter_order }}) </b>
      </div>
    </div>
    <div class="listening-passage row mar">
      <div class="intro_screen question" style="opacity: 1;">
        <h6 class="text-center"><b>Question</b></h6>
        <div class="title_text">
          <span>{{ question_detail.question }}</span>
        </div>
        <hr style="margin:30px 0" />
        <div v-show="!showBar">
          <h6 class="text-center">
            <i class="fa fa-headphones" aria-hidden="true"></i>
            Preparation Time Remaining: <b>{{ prepare_time }}</b> Seconds
          </h6>
          <h6 class="text-center">
            <i class="fa fa-headphones" aria-hidden="true"></i>
            Response Time Remaining: <b>{{ answer_time }}</b> Seconds
          </h6>
        </div>
        <div v-show="showBar">
          <h6 class="col-md-12 text-center record-total" id="record-total-1">
            <b>{{ totalText }}</b>
          </h6>
          <div class="time-container record-time col-md-6 col-md-offset-3">
            <div id="record-bar-1" class="bar" style="width:0%;"></div>
          </div>
        </div>
        <div class="text-center mt-4" v-show="speakingEnd">
          <h6 class="text-center mt-3" style="margin:0">
            <b>My Answer:</b>
          </h6>
          <div
            class="mt-2"
            style="display:flex;justify-content:center;align-items:center;"
          >
            <audio
              id="audio"
              :src="audio_src"
              type="audio/wav"
              controls
              style="width:100%;height:40px;"
            ></audio>
          </div>
          <div v-show="!showOk">
            <div class="text-center mt-3">
              <em>
                Does your recording play properly?
              </em>
            </div>
            <div class="text-center mt-1">
              <el-button size="small" @click="() => speakingAgain()">
                No (Re-record)
              </el-button>
              <el-button size="small" type="primary" @click="upload">
                Yes (Continue)
              </el-button>
            </div>
          </div>
          <div v-show="showOk">
            <div class="text-center text-success mt-3">
              <em>
                Uploaded! Please click "Continue" on the top right corner of the screen.
              </em>
            </div>
          </div>
        </div>
        <GoogleAd v-if="false" :key="question_detail.id" />
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
import GoogleAd from "@/views/ad/GoogleAd";
export default {
  props:["question_detail", "answer", "prepare_time", "answer_time", "mode", "showOk", "audio_src"],
  components:{
    GoogleAd
  },
  data() {
    return {
      showBar: false,
      theAnswer:this.answer,
      totalText: "",
      timer:{},
      speakingEnd: false
    };
  },
  watch: {
    "question_detail.id"() {
      this.isIgnoreTitleAudio();
    }
  },
  created(){
    this.isIgnoreTitleAudio();
  },
  methods: {
    isIgnoreTitleAudio() {
      const calculateQuestionDuration = () => {
        const words = this.question_detail.question.split(" ").length;
        return words * 0.5;
      };
      const questionDuration = calculateQuestionDuration();
      setTimeout(() => {
        const beep_prepare = document.getElementById("beep_prepare");
        const beep_answer = document.getElementById("beep_answer");
        if (this.question_detail.content.title_audio === "") {
          beep_prepare.play();
          beep_prepare.onended = () => {
            this.showBar = true;
            this.recordRun(this.prepare_time,"record-bar-1","record-total-1","prepare");
          };
          beep_answer.onended = () => {
            this.recordRun(this.answer_time,"record-bar-1","record-total-1","answer");
            this.$parent.readyOriginal();
          };
        }
      }, questionDuration * 1000);
    },
    play(){
      let speaking_title = document.getElementById("speaking_title");
      let beep_prepare = document.getElementById("beep_prepare");
      let beep_answer = document.getElementById("beep_answer");
      speaking_title.play();
      speaking_title.onended = function(){
        beep_prepare.play();
      };
      beep_prepare.onended = () => {
        this.showBar = true;
        this.recordRun(this.prepare_time,"record-bar-1","record-total-1","prepare");
      };
      beep_answer.onended = () => {
        this.recordRun(this.answer_time,"record-bar-1","record-total-1","answer");
        this.$parent.readyOriginal();
      };
    },
    async recordRun(audio_time,IdBar,total,type){
      let vm = this;
      var bar = document.getElementById(IdBar);
      var width = 0;
      var second = audio_time;
      if(type == "prepare"){
        type = "Prepare";
      }else if(type == "answer"){
        vm.showBar = true;
        type = "Answer";
      };
      vm.totalText = type + ": " + second +"s ";
      vm.timer = window.setInterval(function(){
        if(bar.style.width == "100%"){
          vm.totalText = "";
          clearInterval(vm.timer);
        }
        second--;
        width += 100/audio_time;
        bar.style.width = width + "%";
        vm.totalText = type + ": " + second +"s ";
        if(Math.ceil(width) >= 100){
          clearInterval(vm.timer);
          if(type == "Prepare"){
            let beep_answer = document.getElementById("beep_answer");
            beep_answer.play();
            vm.showBar = false;
            bar.style.width = 0;
          }else if(type == "Answer"){
            vm.$parent.readyOriginal();
            // vm.showOk = true;
            vm.speakingEnd = true;
          };
          return true;
        }
      },1000);
    },
    speakingAgain(){
      this.play();
      this.showBar = false;
      this.speakingEnd = false;
    },
    upload(){
      this.$parent.uploadAudio();
    },
    postAnswer(){
      clearInterval(this.timer);
      this.$parent.getQuestion("next");
    }
  }
};
</script>
<style src="@/views/toefl/toeflTest/mobile/style/tpo.css" scoped></style>
<style scoped>
.cover {
  height: 100%;
}
.time-container {
  margin: 0 auto;
}
.intro_screen {
  width: 100%;
}
.header-default-btn {
  justify-content: center;
}
</style>
