<template>
  <div id="ReviewQuestion" class="review-show mar">
    <div class="intro_screen cover">
      <div class="panel">
        <table class="table">
          <thead style="background-color: #ccc">
            <tr>
              <th>...</th>
              <th><span>Question</span></th>
              <th><span>Your Answer</span></th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(text, key) in texts"
              class="nop"
              style="background-color: rgb(204, 204, 204);"
              @click="selectQuestion(key)"
              :key="key"
            >
              <td class="question_sum">
                {{ text.question_order }}
              </td>
              <td v-html="text.question"></td>
              <td>
                <a class="your_answer">
                  {{ isAnswered(text.exam_question_id) }}
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReviewQuestion",
  props: ["texts", "allAnswers"],
  methods: {
    selectQuestion(question_index) {
      this.$parent.selectQuestion(question_index);
    },
    isAnswered(exam_question_id) {
      let isSeen = false;
      let isAnswered = false;
      for (let i in this.allAnswers) {
        if (this.allAnswers[i].exam_question_id === exam_question_id) {
          isSeen = true;
          if (this.allAnswers[i].answers.length > 0) {
            isAnswered = true;
          }
        }
      }
      if (isSeen === false) {
        return "Not seen";
      } else {
        if (isAnswered === false) {
          return "Not answered";
        } else {
          return "Answered";
        }
      }
    }
  }
};
</script>

<style src="@/views/toefl/toeflTest/mobile/style/tpo.css" scoped></style>
