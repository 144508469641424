import { render, staticRenderFns } from "./ReviewQuestion.vue?vue&type=template&id=fe140a2c&scoped=true&"
import script from "./ReviewQuestion.vue?vue&type=script&lang=js&"
export * from "./ReviewQuestion.vue?vue&type=script&lang=js&"
import style0 from "@/views/toefl/toeflTest/mobile/style/tpo.css?vue&type=style&index=0&id=fe140a2c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe140a2c",
  null
  
)

export default component.exports