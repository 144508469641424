<template>
  <div style="width: 100%;height: 100%">
    <div class="header-default">
      <div class="header-default-menu">
        <div class="control">
          <div></div>
          <div>
            <div @click="setPage" class="question—btn">
              <el-button size="mini">
                <b>Continue </b>
                <i class="fas fa-arrow-circle-right"></i>
              </el-button>
            </div>
          </div>
        </div>
      </div>
      <div class="header-default-btn">
        <b>Speaking (Task {{ question_detail.chapter_order }}) </b>
      </div>
    </div>
    <div class="listening-passage row mar">
      <div class="intro_screen cover text-center">
        <audio
          id="reading_time"
          :src="question_detail.content.reading_audio"
          @ended="readPassage"
          autoplay
        ></audio>
        <h4 class="reading_time text-center">
          Reading Time:
          <b class="time">{{ time.minute }}:{{ time.second }}</b>
          <!-- <el-button type="danger" size="mini">
            <b class="time_text">{{ time.minute }} : {{ time.second }}</b>
          </el-button> -->
        </h4>
        <hr style="margin:0 0 15px 0" />
        <div
          v-if="showPassage"
          class="reading_time_text"
          v-html="question_detail.content.reading"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {
  components: {},

  mixins: [],

  props: ["question_detail", "mode"],
  data() {
    return {
      imgUrl:
        "https://ivy-way.s3-ap-northeast-1.amazonaws.com/toefl/img/headset.jpeg",
      showPlay: true,
      showPassage: false,
      time: {
        minute: "00",
        second: "45"
      },
      timer: {}
    };
  },
  computed: {},
  watch: {},

  created() {
    if (!this.question_detail.content.reading_audio) {
      this.readPassage();
    }
  },

  methods: {
    readPassage() {
      this.showPassage = true;
      this.countDown(45);
    },
    countDown(seconds_remaining) {
      let vm = this;
      vm.timer = setInterval(() => {
        let minute;
        let second;
        minute = Math.floor(seconds_remaining / 60);
        second = Math.floor(seconds_remaining) - minute * 60;
        // 让个位数的时间正常显示
        if (minute <= 9) minute = "0" + minute;
        if (second <= 9) second = "0" + second;

        this.time.minute = minute;
        this.time.second = second;

        seconds_remaining--;
        if (seconds_remaining == 0) {
          clearInterval(this.timer);
          vm.$parent.setPage();
        }
      }, 1000);
    },
    setPage() {
      clearInterval(this.timer);
      this.$parent.setPage();
    }
  }
};
</script>
<style src="@/views/toefl/toeflTest/mobile/style/tpo.css" scoped></style>
<style scoped>
.intro_screen {
  width: 100%;
}
.mylogo {
  line-height: 80px;
}
.time-container {
  margin: 0 auto;
}
.time_text {
  font-size: 16px;
}
.reading_time_text {
  text-align: left;
  font-size: 14px;
}
</style>
