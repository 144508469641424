<template>
  <div style="width: 100%;height: 100%">
    <div class="header-default">
      <div class="header-default-menu">
        <div class="control">
          <el-dropdown trigger="click" size="small" @command="changeSpeed">
            <span class="audio-speed" style="color:white">
              <b> Speed: {{ speed }} X</b>
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(item, index) in multipleArray"
                :key="index"
                :command="item"
              >
                {{ item }} X
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-popover placement="bottom" width="100" trigger="hover">
            <div class="block">
              <span class="demonstration">Vol</span>
              <el-slider v-model="vol"></el-slider>
            </div>
            <el-button slot="reference" size="mini">
              <b>Volume</b>
            </el-button>
          </el-popover>
          <el-button @click="setPage" size="mini" :disabled="disableContinue">
            <b>START</b>
          </el-button>
        </div>
      </div>
      <div class="header-default-btn"></div>
    </div>

    <div class="listening-passage row mar">
      <div class="intro_screen cover text-center">
        <img class="style_change" :src="imgUrl" />
        <audio
          id="repeat"
          ref="audioPlayer"
          :src="question_detail.content.repeat_audio"
          @ended="endedPlay"
          @error="endedPlay"
        ></audio>
        <h6><b>Replay:</b></h6>
        <h6>
          <b>After the audio, please click "START" to answer questions.</b>
        </h6>
        <div class="time-container col-md-6 col-md-offset-3">
          <div id="bar" style="width:0%;"></div>
        </div>
        <p class="col-md-12" id="total" style="color: #ccc"></p>
        <ReplayText @play="play" />
      </div>
    </div>
  </div>
</template>

<script>
import ReplayText from "@/views/toefl/toeflTest/ReplayText";

export default {
  components: {
    ReplayText
  },

  mixins: [],

  props: ["question_detail", "mode"],
  data() {
    return {
      imgUrl:
        "https://ivy-way.s3-ap-northeast-1.amazonaws.com/toefl/img/headset.jpeg",
      showPlay: true,
      multipleArray: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 2],
      speed: 1,
      vol: 100,
      disableContinue: true,
      timer: null
    };
  },
  computed: {},
  watch: {
    vol() {
      this.changeVol();
    }
  },

  mounted() {
    this.play();
  },

  methods: {
    play() {
      let vm = this;
      this.$nextTick(() => {
        let passage_audio = document.getElementById("repeat");
        passage_audio.load();
        passage_audio.oncanplay = function() {
          passage_audio.playbackRate = vm.speed;
          passage_audio.play();
          vm.run(Math.round(passage_audio.duration));
        };
      });
    },
    endedPlay() {
      this.disableContinue = false;
    },
    setPage() {
      this.$parent.setPage();
    },
    changeVol() {
      let audioPlayer = this.$refs.audioPlayer;
      audioPlayer.volume = this.vol / 100;
    },
    changeSpeed(command) {
      let audioPlayer = this.$refs.audioPlayer;
      audioPlayer.playbackRate = command;
      this.speed = command;
    },
    countDown(seconds) {
      let minute;
      let second;
      minute = Math.floor(seconds / 60);
      second = Math.floor(seconds) - minute * 60;
      // 让个位数的时间正常显示
      if (minute <= 9) minute = "0" + minute;
      if (second <= 9) second = "0" + second;
      return minute + ":" + second;
    },
    run(audio_time) {
      let vm = this;
      let timer;
      let bar = document.getElementById("bar");
      let total = document.getElementById("total");
      let width = 0;
      let time = vm.countDown(audio_time);
      let second = 0;
      let surplus_time;
      if (this.timer) {
        clearInterval(vm.timer);
      }
      timer = window.setInterval(function() {
        second = second + 1 * vm.speed;
        if (second >= audio_time) {
          second = audio_time;
          vm.endedPlay();
        }
        surplus_time = vm.countDown(second);
        width += (100 * vm.speed) / audio_time;
        bar.style.width = width + "%";
        total.innerHTML = surplus_time + "/" + time;
        if (second >= audio_time) {
          bar.style.width = "100%";
          clearInterval(timer);
          return true;
        }
      }, 1000);
      vm.timer = timer;
    }
  }
};
</script>
<style src="@/views/toefl/toeflTest/mobile/style/tpo.css" scoped></style>
<style scoped>
.intro_screen {
  width: 100%;
}
.time-container {
  margin: 0 auto;
}
.mylogo .control {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.mylogo .control .el-dropdown {
  width: 100px;
}
.mylogo .audio-speed {
  display: inline-block;
  width: 100%;
}
.header-default .control .el-dropdown span {
  color: var(--themeColor);
}
.header-default .control {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
</style>
