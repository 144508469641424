<template>
  <div
    id="PassageDefault"
    :class="
      showPassage ? 'col-xs-12 cover' : 'col-xs-12 col-sm-12 col-md-12 cover'
    "
  >
    <div class="intro_screen text cover">
      <h2 class="text-center">{{ question_detail.chapter_title }}</h2>
      <p id="passage" :class="{ mock: mode == '1' }"></p>
    </div>
    <GoogleAd v-if="false" :key="question_detail.id" />
  </div>
</template>

<script>
import $ from "jquery";
import GoogleAd from "@/views/ad/GoogleAd";
export default {
  name: "PassageDefault",
  props: ["showPassage", "question_detail", "mode", "insertTitle"],
  components: {
    GoogleAd
  },
  watch: {
    question_detail() {
      this.setPassage();
    },
    insertTitle(val) {
      $(".insert-option").remove();
      $(".insert-area[data-answer='" + val + "'] .text-success").append(
        " <span class='insert-option'>" +
          this.question_detail.content.click_option +
          "</span>"
      );
      this.setAnswer(
        $(this)
          .parent()
          .data("answer")
      );
    }
  },
  mounted() {
    this.setPassage();
  },
  data() {
    return {};
  },
  methods: {
    setPassage() {
      let passage = document.getElementById("passage");
      if (this.question_detail.chapter_content.html) {
        passage.innerHTML = this.question_detail.chapter_content.html;
        let vm = this;
        $(".paragraph").each(function(item) {
          let questionOrders = $(this).data("light");
          if (questionOrders?.includes(vm.question_detail.question_order)) {
            $(this).addClass("paragraph-anchor");
            $(this).find("b").addClass("light");
          }
        });

        $("[data-light-order]").each(function(item) {
          let questionOrder = $(this).data("light-order");
          if (questionOrder === vm.question_detail.question_order) {
            $(this).addClass("light");
          } else {
            $(this).removeClass("light");
          }
        });
      } else {
        passage.innerHTML = this.question_detail.content.html;
      }

      this.scrollToParagraph();
      if (this.question_detail.type === "click") {
        let dom = document.getElementsByClassName("insert-area");
        for (var i = 0; i < dom.length; i++) {
          if (this.mode === "1") {
            dom[i].innerHTML =
              " <b class='text-success'><span style='font-size:14px;line-height: 16px'>■</span></b> ";
          } else {
            dom[i].innerHTML = ` <b class="text-success">[${dom[i].getAttribute(
              "data-answer"
            )}]  <span style="font-size:14px;line-height: 16px">■</span></b> `;
          }
        }
      }
      let vm = this;
      $(".insert-area .text-success").click(function() {
        $(".insert-option").remove();
        $(this).append(
          " <span class='insert-option'>" +
            vm.question_detail.content.click_option +
            "</span>"
        );
        vm.setAnswer(
          $(this)
            .parent()
            .data("answer")
        );
      });
      $(
        ".insert-area[data-answer='" + this.insertTitle + "'] .text-success"
      ).append(
        ` <span class='insert-option'>
        ${this.question_detail.content.click_option}
        </span>`
      );
      this.setAnswer(
        $(this)
          .parent()
          .data("answer")
      );
    },
    //段落跳转
    scrollToParagraph() {
      let paragraph = document.getElementsByClassName("paragraph-anchor")[0];
      let text = document.getElementsByClassName("text")[0];
      if (paragraph !== undefined) {
        text.scrollTo(0, paragraph.offsetTop);
      }
    },
    setAnswer(answer) {
      this.$emit("insertAnswer", answer);
    }
  }
};
</script>
<style src="@/views/toefl/toeflTest/mobile/style/tpo.css" scoped></style>
<style scoped>
::v-deep .article br {
  display: none;
}
.cover {
  height: 100%;
}
.intro_screen {
  word-wrap: break-word;
  background-color: #fff;
  border: 1px solid #efefef;
  padding: 15px;
  overflow: auto;
  margin: 0 15px;
}
.paragraph-anchor b {
  color: var(--themeColor);
}
.paragraph > b:first-child {
  display: inline-block;
  margin-right: 5px;
}
.insert-area .text-success {
  cursor: pointer;
}
</style>
