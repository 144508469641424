<template>
  <div>
    <div class="intro_screen cover">
      <div class="">
        <p class="q_count text-center">
          Question<b> {{ question_detail.question_order }} </b>
        </p>
        <div class="init_title text">
          <p class="title-p">
            <b>Directions</b>: An introductory sentence for a brief summary of
            the passage is provided below. Complete the summary by selecting the
            THREE answer choices that express the most important ideas in the
            passage. Some sentences do not belong in the summary because they
            express ideas that are not presented in the passage or are minor
            ideas in the passage. <b>This question is worth 2 points.</b>
          </p>
          <el-alert type="success" center :closable="false">
            <h6 style="margin:10px 0">
              Click on your answer choices to move them to the answer space. To
              review the passage, click on <b>VIEW TEXT</b>.
            </h6>
          </el-alert>
          <h6 class="text-center" style="margin-top:20px">
            <b>{{ getQuestion(question_detail.question) }}</b>
          </h6>
        </div>
        <div>
          <div
            class="list-group row"
            :list="myAnswers"
            group="people"
            @change="getAnswer"
          >
            <ul class="three-points">
              <li></li>
              <li></li>
              <li></li>
            </ul>
            <div
              class="list-item"
              v-for="(element, index) in myAnswers"
              @click="restOption(index)"
              :key="element.id"
            >
              {{ element.title }}
            </div>
          </div>
          <h6 class="text-center"><b>Answer Choices</b></h6>
          <!-- <draggable class="" :list="options" group="people"> -->
          <div
            @click="alert(index)"
            class=""
            v-for="(element, index) in options"
            :key="index"
          >
            <div class="list-item ">
              {{ element.title }}
            </div>
          </div>
          <GoogleAd v-if="false" :key="question_detail.id" />
          <!-- </draggable> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import GoogleAd from "@/views/ad/GoogleAd";
import _ from "lodash";

export default {
  components: {
    // draggable
    GoogleAd
  },
  props: ["question_detail", "answers"],
  data() {
    return {
      options: _.cloneDeep(this.question_detail.options),
      myAnswers: []
    };
  },
  mounted() {
    this.getAnswer();
  },
  watch: {
    answers() {
      let options = [];
      let myAnswers = [];
      this.question_detail.options.forEach(option => {
        let index = this.answers.indexOf(option.id);
        if (index > -1) {
          myAnswers.push(option);
        } else {
          options.push(option);
        }
      });
      this.options = options;
      this.myAnswers = myAnswers;
      this.getAnswer();
    }
  },
  methods: {
    alert(index) {
      this.myAnswers.push(this.options[index]);
      this.options.splice(index, 1);
      this.myAnswers = _.sortBy(this.myAnswers, ["number"]);
    },
    restOption(index) {
      this.options.push(this.myAnswers[index]);
      this.myAnswers.splice(index, 1);
      this.options = _.sortBy(this.options, ["number"]);
    },
    getQuestion(val) {
      let text = val.replace(
        /Directions: An introductory sentence for a brief summary of the passage is provided below. Complete the summary by selecting the THREE answer choices that express the most important ideas in the passage. Some sentences do not belong in the summary because they express ideas that are not presented in the passage or are minor ideas in the passage. This question is worth 2 points./,
        ""
      );
      return text;
    },
    getAnswer() {
      if (this.myAnswers.length > 3) {
        let item = this.myAnswers.pop();
        this.options.push(item);
      }
      let answers = [];
      this.myAnswers.forEach(option => {
        answers.push(option.id);
      });
      answers.sort(this.cmp);
      this.$parent.setAnswers(answers);
    },
    cmp(a, b) {
      return a - b;
    }
  }
};
</script>
<style src="@/views/toefl/toeflTest/mobile/style/tpo.css" scoped></style>
<style scoped>
.el-alert--success.is-light {
  background-color: #f2f3f5;
}

.list-group {
  width: 100%;
  min-height: 352px;
  /* max-height: 400px; */
  margin: 20px 0;
  border: 1px solid #ccc;
  padding: 10px 20px;
}
.list-item {
  cursor: pointer;
  min-height: 90px;
  border: 1px solid #cacbcc;
  background-color: #f2f3f5;
  padding: 20px 10px;
  margin: 10px 0;
}
.list-group {
  position: relative;
}
.list-group .three-points {
  position: absolute;
  left: 7px;
}
.list-group .three-points li {
  color: var(--themeColor);
  height: 110px;
  line-height: 110px;
}
</style>
