<template>
  <div style="width: 100%;height: 100%">
    <div class="header-default">
      <div class="header-default-menu">
        <div class="control">
          <div>
            <el-button size="mini">
              <span class="time_show">
                <b>{{ time.minute }}</b>
                <b>:</b>
                <b>{{ time.second }} </b>
              </span>
            </el-button>
          </div>
          <!-- <div>
            <div @click="setPage" class="question—btn">
              <el-button size="mini">
                <b>Continue </b>
                <i class="fas fa-arrow-circle-right"></i>
              </el-button>
            </div>
          </div> -->
        </div>
      </div>
      <div class="header-default-btn">
        <b>Writing (Task {{ question_detail.chapter_order }}) </b>
      </div>
    </div>

    <div class="listening-passage row mar">
      <div
        class="intro_screen cover"
        v-html="question_detail.content.article"
      ></div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */

export default {
  components: {},

  mixins: [],

  props: ["question_detail", "mode"],
  data() {
    return {
      imgUrl:
        "https://ivy-way.s3-ap-northeast-1.amazonaws.com/toefl/img/headset.jpeg",
      showPlay: true,
      time: {
        minute: "03",
        second: "00"
      }
    };
  },
  computed: {},
  watch: {},

  mounted() {
    this.countDown(180);
  },

  methods: {
    setPage() {
      this.$parent.setPage();
    },
    countDown(seconds_remaining) {
      this.timer = setInterval(() => {
        var minute;
        var second;
        seconds_remaining--;
        minute = Math.floor(seconds_remaining / 60);
        second = Math.floor(seconds_remaining) - minute * 60;
        // 让个位数的时间正常显示
        if (minute <= 9) minute = "0" + minute;
        if (second <= 9) second = "0" + second;
        this.time.minute = minute;
        this.time.second = second;
        if (seconds_remaining == 0) {
          this.$parent.setPage();
        }
      }, 1000);
    }
  }
};
</script>
<style src="@/views/toefl/toeflTest/mobile/style/tpo.css" scoped></style>
<style scoped>
.intro_screen {
  width: 100%;
}
.mylogo {
  line-height: 80px;
}
.time-container {
  margin: 0 auto;
}
.intro_screen >>> .article {
  height: 100%;
  overflow: auto;
}
</style>
