<template>
  <div>
    <div class="intro_screen cover">
      <div class="col-md-12">
        <p class="q_count text-center">
          Question<b> {{ question_detail.question_order }} </b>
        </p>

        <div class="init_title text">
          <p class="title-p" v-html="question_detail.question"></p>
          <el-alert type="success" center :closable="false">
            <h6 style="margin:10px 0">
              Drag your answer choices to the spaces where they belong. To
              review the passage, click on <b>VIEW TEXT</b>.
            </h6>
          </el-alert>
        </div>
        <div class="row" v-if="question_detail.note.double">
          <div
            :class="
              question_detail.note.double.length > 2 ? 'col-sm-4' : 'col-sm-6'
            "
            v-for="(question, index) in question_detail.note.double"
            :key="index"
          >
            <h5 class="text-center">
              <b v-if="question_detail.note">
                {{ question_detail.note.double[index] }}
              </b>
              <b> [{{ question_detail.note.double_count[index] }}]</b>
            </h5>
            <div class="select-area">
              <ul class="three-points">
                <li
                  v-for="i in question_detail.note.double_count[index]"
                  :key="i"
                ></li>
              </ul>
              <draggable
                class="list-group row"
                :list="AnswersList[index]"
                group="people"
                @change="getAnswers()"
              >
                <div
                  class="list-item"
                  v-for="element in AnswersList[index]"
                  :key="element.id"
                >
                  {{ element.title }}
                </div>
              </draggable>
            </div>
          </div>
        </div>
        <div>
          <div class="">
            <h5 class="text-center">
              <b>Answer Choices</b>
            </h5>
            <draggable class="row" :list="options" group="people">
              <div
                @click="alert()"
                class="col-sm-6"
                v-for="element in options"
                :key="element.id"
              >
                <div class="list-item ">
                  {{ element.title }}
                </div>
              </div>
            </draggable>
            <GoogleAd v-if="false" :key="question_detail.id" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import _ from "lodash";
import GoogleAd from "@/views/ad/GoogleAd";

export default {
  components: {
    draggable,
    GoogleAd
  },
  props: ["question_detail", "answers"],
  data() {
    return {
      options: _.cloneDeep(this.question_detail.options),
      myAnswers: [],
      Answers: [],
      AnswersList: []
    };
  },
  mounted() {
    this.getAnswersList();
  },
  watch: {},
  methods: {
    alert() {
      this.$message({
        message:
          "Please click and DRAG your answer choices into the space above.",
        type: "warning"
      });
    },
    getAnswersList() {
      if (
        this.question_detail.note &&
        this.question_detail.note.double.length > 1
      ) {
        let AnswersList = [];
        this.question_detail.note.double.map(option => {
          AnswersList.push([]);
        });
        this.AnswersList = AnswersList;
      }

      let options = [];
      if (this.answers.length > 1) {
        this.question_detail.options.map(option => {
          let optionSelected = 0;
          this.answers.map((answer, index) => {
            let key = answer.indexOf(option.id);
            if (key > -1) {
              optionSelected++;
              this.AnswersList[index].push(option);
            }
          });
          if (optionSelected === 0) {
            options.push(option);
          }
        });
        this.options = options;
      }
    },
    getAnswers() {
      if (this.AnswersList && this.AnswersList.length > 1) {
        let answers = [];
        this.AnswersList.map((list, index) => {
          if (list.length > this.question_detail.note.double_count[index]) {
            let item = list.pop();
            this.options.push(item);
          }
          answers[index] = [];
          list.forEach(option => {
            answers[index].push(option.id);
          });
        });
        console.log(answers);
        this.Answers = answers;
        this.$parent.setAnswers(answers);
      }
    },
    // getAnswer(max = 3) {
    //   if (this.myAnswers.length > max) {
    //     let item = this.myAnswers.pop();
    //     this.options.push(item);
    //   }
    //   let answers = [[], []];
    //   this.myAnswers.forEach(option => {
    //     answers[0].push(option.id);
    //   });
    //   this.Answers.forEach(option => {
    //     answers[1].push(option.id);
    //   });
    //   answers[0].sort(this.cmp);
    //   answers[1].sort(this.cmp);
    //   this.$parent.setAnswers(answers);
    // },
    // getRAnswer(max = 2) {
    //   if (this.Answers.length > max) {
    //     let item = this.Answers.pop();
    //     this.options.push(item);
    //   }
    //   let answers = [[], []];
    //   this.myAnswers.forEach(option => {
    //     answers[0].push(option.id);
    //   });
    //   this.Answers.forEach(option => {
    //     answers[1].push(option.id);
    //   });
    //   answers[0].sort(this.cmp);
    //   answers[1].sort(this.cmp);
    //   this.$parent.setAnswers(answers);
    // },
    cmp(a, b) {
      return a - b;
    }
  }
};
</script>
<style src="@/views/toefl/toeflTest/style/tpo.css" scoped></style>
<style scoped>
.el-alert--success.is-light {
  background-color: #f2f3f5;
}
.list-group {
  width: 100%;
  min-height: 352px;
  /* max-height: 400px; */
  margin: 20px 0;
  border: 1px solid #ccc;
  padding: 10px;
}
.list-item {
  min-height: 90px;
  border: 1px solid #cacbcc;
  background-color: #f2f3f5;
  padding: 20px 10px;
  margin: 10px;
}
.select-area {
  position: relative;
}
.select-area .three-points {
  position: absolute;
  left: 7px;
}
.select-area .three-points li {
  color: var(--themeColor);
  height: 110px;
  line-height: 110px;
}
</style>
