<template>
  <div class="header-default">
    <div class="header-default-menu">
      <div class="control">
        <div class="review">
          <div class="words text-center">
            <b>Word Count: </b>
            <b style="color: white">
              {{ count }}
            </b>
          </div>
        </div>
        <div @click="$emit('dook', 'next')" class="question—btn">
          <el-button size="mini">
            <b>Submit </b>
            <i class="fas fa-arrow-circle-right"></i>
          </el-button>
        </div>
      </div>
    </div>
    <div class="header-default-btn">
      <div>
        Task {{ question_detail.chapter_order }} -
        <b v-html="question_detail.chapter_title"></b>
      </div>
      <div class="time">
        <div @click="timeShow = !timeShow" v-show="!timeShow" size="mini">
          <b>TIME</b>
        </div>
        <div
          type="danger"
          @click="timeShow = !timeShow"
          size="mini"
          v-show="timeShow"
        >
          <span class="time_show">
            <b>{{ time.minute }}</b>
            <b>:</b>
            <b>{{ time.second }} </b>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["question_detail", "answers", "seconds", "count", "mode"],
  watch: {
    question_detail() {
      this.countDown(this.seconds);
    }
  },
  mounted() {
    this.countDown(this.seconds);
  },
  methods: {
    countDown(seconds_remaining) {
      let minute = Math.floor(seconds_remaining / 60);
      let second = Math.floor(seconds_remaining) - minute * 60;
      // 让个位数的时间正常显示
      if (minute <= 9) minute = "0" + minute;
      if (second <= 9) second = "0" + second;
      this.time.minute = minute;
      this.time.second = second;
      this.testTimeInterval = setInterval(() => {
        var minute;
        var second;
        minute = Math.floor(seconds_remaining / 60);
        second = Math.floor(seconds_remaining) - minute * 60;
        // 让个位数的时间正常显示
        if (minute <= 9) minute = "0" + minute;
        if (second <= 9) second = "0" + second;
        this.time.minute = minute;
        this.time.second = second;
        seconds_remaining--;
        if (seconds_remaining < 0) {
          clearInterval(this.testTimeInterval);
          this.$emit("dook", "next");
        }
      }, 1000);
    }
  },
  data() {
    return {
      testTimeInterval: null,
      time: {
        minute: "",
        second: ""
      },
      timeShow: true
    };
  }
};
</script>
<style src="@/views/toefl/toeflTest/mobile/style/tpo.css" scoped></style>
<style scoped>
.your_ansers {
  line-height: 80px;
}
.words {
  color: white;
}
</style>
