<template>
  <div id="QuestionDefault" class="directions-text directions-text-writing">
    <div class="intro_screen" :style="changeTop">
      <div class="title_text directions-style">
        {{ question_detail.question }}
      </div>
      <hr style="margin:15px 0" />
      <div class="article">
        <div class="cover article-text">
          <!-- <h6 class="article-title text-center"><b>Question</b></h6> -->
          <b
            class="writing-article"
            v-html="question_detail.content.article"
          ></b>
        </div>
      </div>
    </div>
    <div class="change-box" :style="changeCenter">
      <template v-if="boxType === 0">
        <el-button
          type="primary"
          size="small"
          style="width:100%"
          @click="boxType = 1"
        >
          <i class="fas fa-arrow-down"></i>
        </el-button>
        <el-button
          type="primary"
          size="small"
          style="width:100%;margin:0"
          @click="boxType = 2"
        >
          <i class="fas fa-arrow-up"></i>
        </el-button>
      </template>
      <template v-if="boxType === 1">
        <el-button
          type="primary"
          size="small"
          style="width:100%;margin:0"
          @click="boxType = 3"
        >
          <i class="fas fa-arrow-down"></i>
        </el-button>
        <el-button
          type="primary"
          size="small"
          style="width:100%;margin:0"
          @click="boxType = 0"
        >
          <i class="fas fa-arrow-up"></i>
        </el-button>
      </template>
      <template v-if="boxType === 2">
        <el-button
          v-show="boxType === 2"
          type="primary"
          size="small"
          style="width:100%;margin:0"
          @click="boxType = 0"
        >
          <i class="fas fa-arrow-down"></i>
        </el-button>
        <el-button
          v-show="boxType === 2"
          type="primary"
          size="small"
          style="width:100%;margin:0"
          @click="boxType = 4"
        >
          <i class="fas fa-arrow-up"></i>
        </el-button>
      </template>
      <template v-if="boxType === 3">
        <el-button
          type="info"
          size="small"
          style="width:100%;margin:0"
          disabled
        >
          <i class="fas fa-arrow-down"></i>
        </el-button>
        <el-button
          type="primary"
          size="small"
          style="width:100%;margin:0"
          @click="boxType = 1"
        >
          <i class="fas fa-arrow-up"></i>
        </el-button>
      </template>
      <template v-if="boxType === 4">
        <el-button
          type="primary"
          size="small"
          style="width:100%;margin:0"
          @click="boxType = 2"
        >
          <i class="fas fa-arrow-down"></i>
        </el-button>
        <el-button
          type="info"
          size="small"
          style="width:100%;margin:0"
          disabled
        >
          <i class="fas fa-arrow-up"></i>
        </el-button>
      </template>
    </div>
    <div class="article-answer" :style="changeBottom">
      <div class="button-group" style="width:100%;">
        <div style="display:flex;margin: 0 -10px 10px">
          <div style="width:33.3%;padding:0 10px">
            <el-button size="small" style="width:100%" @click="copy">
              Copy
            </el-button>
          </div>
          <div style="width:33.3%;padding:0 10px">
            <el-button size="small" style="width:100%" @click="cut">
              Cut
            </el-button>
          </div>
          <div style="width:33.3%;padding:0 10px">
            <el-button size="small" style="width:100%" @click="paste">
              Paste
            </el-button>
          </div>
        </div>
      </div>
      <el-input
        id="answer"
        type="textarea"
        style="width:100%;font-size:14px;flex-grow: 1"
        placeholder="Please type your response here"
        v-model="theAnswer"
      >
      </el-input>
    </div>
    <GoogleAd v-if="false" :key="question_detail.id" />
  </div>
</template>

<script type="text/ecmascript-6">
import GoogleAd from "@/views/ad/GoogleAd";

export default {
  props:["question_detail", "answer", "prepare_time", "answer_time"],
  components: {
    GoogleAd
  },
  computed:{
    changeTop() {
      let str = "";
      if (this.boxType === 0) {
        str = "height: 47%";
      }
      if (this.boxType === 1) {
        str = "height: 69%";
      }
      if (this.boxType === 2) {
        str = "height: 25%";
      }
      if (this.boxType === 3) {
        str = "height: 94%";
      }
      if (this.boxType === 4) {
        str = "display:none";
      }
      return str;
    },
    changeBottom() {
      let str = "";
      if (this.boxType === 0) {
        str = "height: 47%";
      }
      if (this.boxType === 1) {
        str = "height: 25%";
      }
      if (this.boxType === 2) {
        str = "height: 69%";
      }
      if (this.boxType === 3) {
        str = "display:none";
      }
      if (this.boxType === 4) {
        str = "height: 94%";
      }
      return str;
    },
    changeCenter() {
      let str = "height: 6%";
      return str;
    }
  },
  data() {
    return {
      showBar: false,
      theAnswer:this.answer,
      totalText: "",
      boxType: 0
    };
  },
  watch: {
    answer(){
      this.theAnswer = this.answer;
    },
    theAnswer() {
      this.getAnswer();
    }
  },
  methods: {
    getAnswer(){
      let answers = [];
      answers.push(this.theAnswer);
      this.$parent.setAnswers(answers);
    },
    copy(){
      this.clipbrd = this.getSelect();
    },
    cut(){
      let clipbrd = "";
      let textBox = document.getElementById("answer");
      textBox.focus();
      if(textBox.selectionStart >= 0){
        clipbrd = textBox.value.substring(textBox.selectionStart,textBox.selectionEnd);
      }else if(document.selection){
        clipbrd = document.selection.createRange().text;
      }
      this.clipbrd = clipbrd;
      let str = textBox.value.substring(0, textBox.selectionStart) + textBox.value.substring(textBox.selectionEnd,textBox.value.length);
      this.theAnswer = str;
    },
    paste(){
      let obj = document.getElementById("answer");
      let str = this.clipbrd;
      obj.focus();
      if (document.selection) {
        var sel = document.selection.createRange();
        sel.text = str;
      } else if (typeof obj.selectionStart == "number"
          && typeof obj.selectionEnd == "number") {
        var startPos = obj.selectionStart, endPos = obj.selectionEnd, cursorPos = startPos, tmpStr = obj.value;
        obj.value = tmpStr.substring(0, startPos) + str
            + tmpStr.substring(endPos, tmpStr.length);
        cursorPos += str.length;
        obj.selectionStart = obj.selectionEnd = cursorPos;
      } else {
        obj.value += str;
      }
      this.theAnswer = obj.value;
    },
    getSelect(){
      let textBox = document.getElementById("answer");
      let str = "";
      if(textBox.selectionStart >= 0){
        str = textBox.value.substring(textBox.selectionStart,textBox.selectionEnd);
      }else if(document.selection){
        str =  document.selection.createRange().text;
      }
      return str;
    }
  }
};
</script>
<style src="@/views/toefl/toeflTest/mobile/style/tpo.css" scoped></style>
<style scoped>
.intro_screen {
  word-wrap: break-word;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  /* padding: 2%; */
  overflow: auto;
  margin: 0;
}
.time-container {
  margin: 0 auto;
}
#QuestionDefault >>> .el-textarea textarea {
  height: 100%;
}
.directions-text {
  padding: 15px;
}
.intro_screen {
  height: 50%;
}
.article-answer {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
}
::v-deep .el-textarea textarea {
  font-size: 14px;
  padding: 5px;
}
.title_text,
.writing-article {
  font-size: 14px;
}
.directions-text-writing {
  top: 80px;
}
.change-box {
  padding: 0;
  width: 100%;
  height: 12%;
  display: flex;
  justify-content: space-between;
}
</style>
