import { render, staticRenderFns } from "./QuestionRepeat.vue?vue&type=template&id=00c71bf6&scoped=true&"
import script from "./QuestionRepeat.vue?vue&type=script&lang=js&"
export * from "./QuestionRepeat.vue?vue&type=script&lang=js&"
import style0 from "@/views/toefl/toeflTest/mobile/style/tpo.css?vue&type=style&index=0&id=00c71bf6&scoped=true&lang=css&"
import style1 from "./QuestionRepeat.vue?vue&type=style&index=1&id=00c71bf6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00c71bf6",
  null
  
)

export default component.exports