<template>
  <div
    id="TestIntroduction"
    class="directions"
    style="width: 100%;height: 100%"
  >
    <div class="header-default">
      <div class="header-default-menu">
        <el-button
          style="margin:0 auto;width：100%"
          @click="$emit('showintroductionclick')"
          size="mini"
        >
          <b>Continue</b>
        </el-button>
      </div>
      <div class="header-default-btn">
        <div>
          Passage {{ question_detail.chapter_order }} -
          <b v-html="question_detail.chapter_title"></b>
        </div>
      </div>
    </div>

    <div class="directions-text row mar">
      <div class="intro_screen cover">
        <h3 class="title-p text-center">Reading Section Directions</h3>
        <p>
          In this part of the Reading section, you will read 2 passages. In the
          test you will have 36 minutes to read the passages and answer the
          questions.
        </p>
        <p>
          Most questions are worth 1 point but the last question in this set is
          worth more than 1 point. The directions indicate how many points you
          may receive.
        </p>
        <p>
          Some passages include a word or phrase that is underlined in green.
          Click on the word or phrase to see a definition or an explanation.
        </p>
        <p>
          When you want to move to the next question, click on Next. You may
          skip questions and go back to them later if you want to return to
          previous questions, click on Back. You can click on Review at any time
          and the review screen will show you which questions you have answered
          and which you have not answered. From this review screen, you may go
          directly to any question you have already seen in the Reading section.
        </p>
        <p>Click on Continue to go on.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["mode", "question_detail"],
  methods: {},
  data() {
    return {};
  }
};
</script>
<style src="@/views/toefl/toeflTest/mobile/style/tpo.css" scoped></style>
<style scoped>
.mylogo {
  line-height: 80px;
}
.header-default-menu {
  height: 55px;
  line-height: 55px;
}
::v-deep .el-button {
  margin-top: 0;
}
</style>
